import { Flex, css, SystemProps, Box, Text } from "@storyofams/react-ui";

import { Slider } from "../../components";
import { SeeMoreButton } from "../../components/common/Story/molecules/TitleCombo";
import { getLinkProps } from "../../lib";
import { ProductByHandleQuery } from "../../lib/shopify/sdk";

import { AddToCartButton, ProductCard } from "./components";
import { BundleCard } from "./components/BundleCard";

interface ProductSliderProps {
  products?: ProductByHandleQuery["productByHandle"][];
  title?: string;
  description?: string;
  link?: any;
  small?: boolean;
  withAddToCartButton?: boolean;
  linkLabel?: string;
  onClick?(): void;
  isBundles?: boolean;
  centered?: boolean;
}

export const ProductSlider = ({
  products,
  title,
  description,
  link,
  small = false,
  withAddToCartButton,
  onClick,
  linkLabel,
  isBundles,
  centered,
  ...props
}: ProductSliderProps & SystemProps) => {
  return (
    <Flex flexDirection="column" {...props}>
      <Box
        mb={small ? 0 : ["32px", "40px"]}
        display={"flex"}
        flexDirection={["column", "row"]}
        justifyContent="space-between"
        alignItems={"center"}
        textAlign={["center", "start"]}
        width={"100%"}
      >
        <Box>
          {title && (
            <Text
              lineHeight={"46.8px"}
              fontFamily={"DINPro"}
              fontSize={"36px"}
              fontWeight={700}
            >
              {title}
            </Text>
          )}
          {description && (
            <Text fontSize={"18px"} lineHeight={"25.2px"}>
              {description}
            </Text>
          )}
        </Box>
        {linkLabel && getLinkProps(link) && (
          <SeeMoreButton mt={["20px", 0]} link={link}>
            {linkLabel}
          </SeeMoreButton>
        )}
      </Box>
      <Slider
        centerInsufficientSlides={centered}
        spaceBetween={"26px"}
        small={small}
      >
        {products?.map((product, idx) => (
          <Box key={product?.id}>
            {isBundles ? (
              <BundleCard
                key={product?.id}
                {...product}
                onClick={onClick}
                small={small}
                index={idx}
              />
            ) : (
              <ProductCard
                key={product?.id}
                {...product}
                onClick={onClick}
                small={small}
                index={idx}
              />
            )}

            {withAddToCartButton && (
              <AddToCartButton mt={"20px"} product={product} />
            )}
          </Box>
        ))}
      </Slider>
    </Flex>
  );
};
