import React, { useMemo } from "react";
import { Box } from "@storyofams/react-ui";

const AnimatedCarousel = ({
  totalDots = 5,
  initialPlace = 0,
  lastPlace,
  currentPlace,
  initialSize = 18,
  mainColor = "grey400",
}) => {
  const actualLastPlace = lastPlace ?? totalDots - 1;

  const dotPositions = useMemo(() => {
    const range = actualLastPlace - initialPlace;
    const currentPosition = (currentPlace - initialPlace) / range;
    return Array.from({ length: totalDots }, (_, i) => {
      const dotPosition = i / (totalDots - 1);
      return (dotPosition - currentPosition) * (totalDots - 1);
    });
  }, [totalDots, initialPlace, actualLastPlace, currentPlace]);
  return (
    <Box
      position={"relative"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      left={currentPlace * -1}
    >
      {dotPositions.map((position, index) => {
        const distance = Math.abs(position);
        const size = Math.max(4, initialSize - distance * 2);
        const opacity = Math.max(0.2, 1 - distance * 0.3);

        return (
          <Box
            width={size + "px"}
            height={size + "px"}
            opacity={opacity}
            key={index}
            backgroundColor={index === currentPlace ? mainColor : "grey200"}
            mr={0.5}
            ml={!index ? 0 : 0.5}
            borderRadius={"full"}
            style={{
              transition: "all",
              animationDuration: "300 ease-in-out",
            }}
          />
        );
      })}
    </Box>
  );
};

export default AnimatedCarousel;
